import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import VisualizacaoProposta from './proposta';

function App() {
    return (

        <BrowserRouter>
            <Routes>
                <Route exact path="/:id" element={<VisualizacaoProposta />} />
            </Routes>
        </BrowserRouter>

    );
}

export default App;