import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { decode as base64_decode } from 'base-64';
import { useParams } from "react-router-dom";

function VisualizacaoProposta() {

    const endereco = process.env.REACT_APP_ENDERECO_CONEXAO
    const { id } = useParams();

    useEffect(() => {
        GetProposta(id)
    }, [])

    const [loadingProposta, setLoadingProposta] = useState(false)
    const [loading, setLoading] = useState(false)

    const [telaAceite, setTelaAceite] = useState(false)
    const [telaValidaToken, setTelaValidaToken] = useState(false)


    const [proposta, setProposta] = useState(false)
    const [itensProposta, setItensProposta] = useState([])

    const [itemEscolhido, setItemEscolhido] = useState({})
    const [tokenRecebido, setTokenRecebido] = useState('')

    const [propostaRD, setPropostaRD] = useState(false)

    function ApenasNumeros(Texto) {
        const p1 = Texto.replace('(', '')
        const p2 = p1.replace(')', '')
        const p3 = p2.replace('-', '')
        const p4 = p3.replaceAll(' ', '')
        return p4
    }

    async function GetProposta(identificacao) {

        setLoadingProposta(true)

        const url = endereco + '/propostas/detalhe'

        const body = {
            proposta: base64_decode(identificacao)
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoadingProposta(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoadingProposta(false)
        }

        requisicao.onload = function () {
            setLoadingProposta(false)
            if (requisicao.status === 200) {
                setPropostaRD(false)
                setProposta(JSON.parse(this.response))
                GetItensPropostas(identificacao)
            } else {
                GetPropostaRD(identificacao)
            }
        }
    }
    async function GetPropostaRD(identificacao) {

        setLoadingProposta(true)

        const url = endereco + '/propostas/rd/detalhe'

        const body = {
            cliente: identificacao
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoadingProposta(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoadingProposta(false)
        }

        requisicao.onload = function () {
            setLoadingProposta(false)
            if (requisicao.status === 200) {
                setProposta(JSON.parse(this.response))

                if (JSON.parse(this.response).tipo == 'RD') {
                    setPropostaRD(true)
                } else {
                    setPropostaRD(false)
                }

                const resposta = JSON.parse(this.response)

                setItensProposta(resposta.propostas.map(item => {
                    return {
                        item_descricao: item.titulo,
                        item_periodicidade: item.periodicidade,
                        item_valor: item.valor,
                        item_desconto: item.valor,
                        valor_final: item.valor
                    }
                }))
            } else {
                alert("Proposta Não Localizada no RD")
            }
        }
    }

    function EnviarToken(telefone) {

        setLoading(true)

        const url = endereco + '/sms/enviar'

        const body = {
            telefone: telefone
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            setLoading(false)
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            setLoading(false)
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                setTelaValidaToken(true)
            }
        }
    }

    function ValidarToken(telefone, codigo) {

        const url = endereco + '/sms/validar'

        const body = {
            telefone: telefone,
            codigo: codigo
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onload = function () {
            const statusRecebido = JSON.parse(this.response)
            const resposta = statusRecebido.message
            if (requisicao.status === 200 && resposta === 'OK') {
                GerarLinkPagamento(itemEscolhido?.item_periodicidade, itemEscolhido?.valor_final)
            } else {
                alert("Falha na Validação de Identidade")
            }
        }
    }

    function GerarLinkPagamento(periodo, valor) {

        setLoading(true)

        const url = endereco + '/propostas/pagamento/link'

        let recorrencia = ""

        if (periodo == 'MENSAL') {
            recorrencia = 'MONTHLY'
        }

        if (periodo == 'TRIMESTRAL') {
            recorrencia = 'QUARTERLY'
        }

        if (periodo == 'SEMESTRAL') {
            recorrencia = 'SEMIANNUALLY'
        }

        if (periodo == 'ANUAL') {
            recorrencia = 'YEARLY'
        }

        const body = {
            "cliente": propostaRD ? proposta?.empresa : proposta?.prop_cliente,
            "valor": valor,
            "recorrencia": recorrencia
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            setLoading(false)
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            setLoading(false)
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onload = function () {
            const statusRecebido = JSON.parse(this.response)
            const resposta = statusRecebido.link
            setLoading(false)
            if (requisicao.status === 200) {
                window.open(resposta, '_blank').focus();
            } else {
                alert("Falha ao Gerar Link de Pagamento, Contate nosso Suporte!")
            }
        }
    }

    function GetItensPropostas(proposta) {

        setLoadingProposta(true)

        const url = endereco + '/propostas/itens/listar'

        let body = {
            "proposta": base64_decode(proposta)
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoadingProposta(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoadingProposta(false)
        }

        requisicao.onload = function () {
            setLoadingProposta(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setItensProposta(resposta)
            } else {
                setItensProposta([])
            }
        }
    }

    const colunas = [
        {
            name: 'Descrição',
            selector: row => row.item_descricao,
            wrap: true

        },
        {
            name: 'Periodicidade',
            selector: row => row.item_periodicidade,
            wrap: true,
            right: true

        },
        {
            name: 'Valor do Plano',
            selector: row => 'R$ ' + Number(row.item_valor).toFixed(2),
            right: true,
            style: 'color: black',
        },
        {
            name: 'Desconto Concedido',
            selector: row => 'R$ ' + Number(row.item_desconto).toFixed(2),
            right: true,
            style: 'color: blue',
            omit: propostaRD
        },
        {
            name: 'Valor Final',
            selector: row => 'R$ ' + Number(row.valor_final).toFixed(2),
            right: true,
            style: 'color: green',
            omit: propostaRD
        }
    ];

    return (

        <>

            {!loadingProposta &&
                <div style={{ margin: 0 }}>


                    <div className="topo">
                        <div className="topo-box">
                            <div className="topo-box-logo">
                                <img src="http://gestaodevalidades.com.br/wp-content/uploads/2022/10/branco.png" />
                            </div>
                            <div className="topo-divisor">

                            </div>
                            <div className="topo-box-nome-cliente">
                                <h1>{propostaRD ? proposta?.empresa : proposta?.prop_cliente}</h1>
                            </div>

                        </div>
                    </div>
                    <div className="intro">
                        <h2><em>Olá {propostaRD ? proposta?.propostas?.[0].detalhes.find(x => x.custom_field.label == 'Nome Destinatário Proposta')?.value : proposta?.prop_contato}, </em></h2>

                        <p>
                            Conforme solicitado, apresentamos aqui nossa proposta para uso do aplicativo Gestão de Validades.
                        </p>
                        <br />
                        <p>
                            Nossa missão é tornar as organizações mais eficientes fornecendo soluções tecnológicas acessíveis,
                            permitindo através delas melhorar a gestão da empresa.
                        </p>
                        <br />
                        <p>
                            Inovação, ética, foco no cliente, excelência em serviços e compromisso com resultados são valores presentes
                            em nossa filosofia empresarial. Desta forma temos a certeza de estar oferecendo a melhor relação custo
                            beneficio no fornecimento de produtos / serviços para sua empresa.
                        </p>
                    </div>
                    <div className="intro-footer">
                        <p>Everton Lopes , Idealsys</p>
                    </div>
                    <div className="desafio">
                        <h2>O Desafio</h2>
                        <p>
                            Administrar o vencimento dos produtos na gôndola sempre foi um desafio nunca vencido, o uso de planilha e
                            anotações em papeis ou arquivos diversos sempre falham deixando sua loja vulnerável a fiscalizações e
                            penalidades por conta de falhas no processo.
                        </p>
                        <br />
                        <p>
                            Processos e rotinas morosas são criadas para tentar corrigir esses erros, porem se provam falhas e fadadas
                            ao fracasso.
                        </p>
                        <br />
                        <p>
                            Com o <strong>Gestão de Validades</strong> vai ser diferente, integrado ao sistema ERP da loja agiliza a
                            exibição dos
                            produtos com as validades captadas no recebimento, além de permitir ágil lançamento de produtos de produção
                            própria.
                        </p>
                        <br />
                        <p>
                            Possui dashboard com informações precisas para análise gerencial e acompanhamento da evolução dos trabalhos
                            realizados em loja.
                        </p>
                        <br />
                        <p>
                            Com a possibilidade de alertas programados para grupos de pessoas distintas, o aplicativo Gestão de
                            Validades veio revolucionar sua operação de Prevenção de Perdas no quesito Validades permeando meios de
                            alcançar a tão sonhada meta desejada.
                        </p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="solucao">
                        <h2>Nossa Solução</h2>
                        <p>
                            Aplicativo que auxilia a operação na gestão do processo de monitoramento das validades de produtos na loja e
                            no estoque.
                        </p>
                        <br />
                        <p>
                            Com telas intuitivas e de fácil operação o aplicativo <strong>GESTÃO DE VALIDADES</strong> possui a proposta
                            de reduzir o
                            tempo despendido pela operação para esse controle , além de oferecer Dashboard de monitoramento e análise da
                            informação.
                        </p>
                        <br />
                        <p>
                            Desenvolvido sob a mais alta tecnologia é super flexível em relação a combinações de configurações de
                            alertas sendo eles enviados por push, e-mail ou whatsapp
                        </p>
                        <br />
                        <br />
                        <br />
                        <h3>Vantagens</h3>
                        <ul>
                            <li>
                                Disponível na play store;
                            </li>
                            <li>
                                Fácil integração com ERP`s;
                            </li>
                            <li>
                                Alta disponibilidade;
                            </li>
                            <li>
                                Flexibiidade nos parâmetros de alertas;
                            </li>
                            <li>
                                Dashboard elaborado à facilitar a análise do gestor;
                            </li>
                            <li>
                                Acompanhe o desempenho da equipe pelo Painel Gerencial;
                            </li>
                        </ul>
                        <br />
                        <p>
                            Tudo isso e muito mais implementações que já estão presentes em nosso roadmap.
                        </p>
                        <br />
                        <br />
                        <br />
                        <h2>Cronograma</h2>
                        <p>
                            A duração do projeto dependerá de fatores como a cultura da empresa, facilidade de acesso aos dados,
                            disponibilidade da equipe para treinamento entre outros fatores que podem influenciar na velocidade do
                            efetivo resultado e ganho de tempo que o aplicativo tende a oferecer.
                        </p>
                        <br />
                        <p>
                            A configurações e extração dos dados têm previsão de início 10 dias após a assinatura da proposta, podendo
                            ser agendada conforme disponibilidade do cliente e da nossa equipe de implantação. Após o aceite nossa
                            equipe de suporte faz contato com o responsável pelo TI para dar inicio ás tratativas.
                        </p>
                        <br />
                        <p>
                            O período de extração dos dados pode variar conforme o volume a ser processado, logo após a conclusão do
                            mesmo o cliente pode informar disponibilidade para realizar o treinamento. Vale lembrar que o treinamento
                            será realizado de forma remota sem custo adicional, caso o cliente opte por presencial será apresentado
                            respectiva proposta para tal.
                        </p>
                        <br />
                        <p>
                            Após os primeiros 30 dias de trabalhos nossa equipe se dispõe a realizar reunião de feedback para
                            alinhamento e análise da evolução dos trabalhos, avaliando os resultados alcançados e realinhar as
                            estratégias para atingir os objetivos almejados.
                        </p>
                        <br />
                    </div>
                    <div className="banner">
                        <h1>"PARA <strong>PERDAS</strong> O MELHOR REMÉDIO É A PREVENÇÃO"</h1>
                    </div>
                    <div className="investimento">
                        <h2>Investimento Total</h2>
                        <div className="topo-divisor-investimento">

                        </div>
                        <br />
                        <p>
                            O valor apresentado é referente ao aplicativo Gestão de Validades disponibilizando os seguintes recursos :
                        </p>
                        <br />
                        <ul>
                            <li>
                                Cadastro de {propostaRD ? proposta?.propostas?.[0].detalhes.find(x => x.custom_field.label == 'Times')?.value : proposta?.prop_qtde_times} Times/Lojas
                            </li>
                            <li>
                                Até {propostaRD ? proposta?.propostas?.[0].detalhes.find(x => x.custom_field.label == 'Usuários')?.value : proposta?.prop_qtde_membros} usuários por Time
                            </li>
                            <li>
                                Integração com ERP
                            </li>
                            <li>
                                Agendamento de alertas por e-mail, push e Whatsapp
                            </li>
                            <li>
                                Acesso ao Dashbord Gerencial
                            </li>
                            <li>
                                Cadastro de produtos <strong>ilimitados</strong>
                            </li>
                            <li>
                                Coletas <strong>ilimitadas</strong>
                            </li>
                            <li>
                                Relatorios PDF e Excel
                            </li>
                            <li>
                                Gestão de Times
                            </li>
                            <li>
                                Gestão Usuário/Departamento
                            </li>
                            <li>
                                Configuração de Destaques por Cores
                            </li>
                            <li>
                                Catalogo digital(módulo com custo adicional)**
                            </li>
                            <li>
                                Etiqueta Promocional (módulos com custo adicional)**
                            </li>
                            <li>
                                Cartazeamento(em desenvolvimento)
                            </li>
                            <li>
                                Além de acesso á algumas funcionalidades que estão por "sair do forno"
                            </li>
                        </ul>
                        <br />
                        <h3>Condiçoes Comerciais</h3>
                        <br />
                        <br />

                        {/* {itensProposta.map(item => {
                            return (
                                <p>{item.item_descricao}</p>
                            )
                        })} */}



                        <DataTable
                            columns={colunas}
                            data={itensProposta}
                            onRowClicked={(row, index) => {
                                setTelaValidaToken(false)
                                setItemEscolhido(row)
                                setTelaAceite(true)
                            }}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                            pointerOnHover={true}
                            noDataComponent={"Nenhum Registro Encontrado =/"}


                        />


                        <br />
                        <br />
                        <br />
                    </div>

                    <div className="footer">
                        <div className="footer-item">
                            <img src="http://www.idealsys.com.br/wp-content/uploads/2016/01/logo-footer.png" width="314" heigth="66" />
                        </div>
                        <div className="footer-item">
                            <p><strong>Everton Lopes</strong></p>
                            <p>
                                (11) 2358-2466
                            </p>
                            <p>
                                everton@idealsys.com.br
                            </p>
                            <br />
                            <p>
                                Av. Dr. Timóteo Penteado, 3499
                                Vila Galvao, Guarulhos - SP
                                CEP: 07.061-002
                            </p>
                        </div>
                    </div>

                </div>

            }



            <Modal
                show={loadingProposta}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <Row>
                        <Col style={{ textAlign: 'center' }}>
                            Carregando Proposta Comercial
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                show={telaAceite}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="modal-50"
                onHide={() => { setTelaAceite(false) }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Aceite de Proposta
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p>Parabéns pela escolha, abaixo vamos confirmar sua identidade!</p>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                            <p>Número de Telefone Cadastrado na Proposta: <strong>(**) *****-{propostaRD ? proposta?.propostas?.[0].detalhes.find(x => x.custom_field.label == 'Telefone Aceite Proposta')?.value.substring(7) : proposta?.prop_whatsapp?.substring(11)}</strong></p>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col>
                            <p>Você escolheu a opção <strong>{itemEscolhido?.item_descricao}</strong> no Valor de <strong>R$ {Number(itemEscolhido?.valor_final).toFixed(2)}</strong></p>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white', marginRight: 0 }} onClick={() => {
                                GerarLinkPagamento(itemEscolhido?.item_periodicidade, itemEscolhido?.valor_final)
                            }}>Confirmar</Button>
                        </Col>
                    </Row>

                    {/* {!telaValidaToken && !loading &&
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white', marginRight: 0 }} onClick={() => {
                                    EnviarToken(ApenasNumeros(propostaRD ? proposta?.propostas?.[0].detalhes.find(x => x.custom_field.label == 'Telefone Aceite Proposta')?.value : proposta?.prop_whatsapp))
                                }}>Enviar SMS</Button>
                            </Col>
                        </Row>}
                    {loading &&
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <LineWave
                                    height="100"
                                    width="100"
                                    color="#436996"
                                    ariaLabel="line-wave"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    firstLineColor=""
                                    middleLineColor=""
                                    lastLineColor=""
                                />
                            </Col>
                        </Row>
                    }
                    {telaValidaToken &&
                        <>
                            <Row>
                                <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Token</Form.Label>
                                        <Form.Control size="sm" type="text" onChange={(e) => { setTokenRecebido(e.target.value) }} value={tokenRecebido} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white', marginRight: 0 }} onClick={() => {
                                        EnviarToken(ApenasNumeros(propostaRD ? proposta?.propostas?.[0].detalhes.find(x => x.custom_field.label == 'Telefone Aceite Proposta')?.value : proposta?.prop_whatsapp))
                                    }}>Reenviar SMS</Button>
                                </Col>
                                <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white', marginRight: 0 }} onClick={() => {
                                        ValidarToken(ApenasNumeros(propostaRD ? proposta?.propostas?.[0].detalhes.find(x => x.custom_field.label == 'Telefone Aceite Proposta')?.value : proposta?.prop_whatsapp), tokenRecebido)
                                    }}>Confirmar Aceite</Button>
                                </Col>
                            </Row>
                        </>
                    } */}
                </Modal.Body>
            </Modal>

        </>

    )
}

export default VisualizacaoProposta